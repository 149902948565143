import React, {  useState } from "react";
import { useTranslation, LocaleFormatter } from "locales";
import LoginSlogan from "@components/business/login/login.slogan";
import LoginFormContainer from "@components/business/login/login-form.container";
import { CContainer, CInputGroup, CForm, CCol, CRow, CFormInput } from "@coreui/react";
import companyLogo from "@assets/images/company-logo.svg";
import { useForm } from "react-hook-form";
import AppButton from "@components/core/form/controls/button/app-button";
import { NavLink, useSearchParams, useNavigate } from "react-router-dom";
import AppFormFieldErrorMessage from "@components/core/form/app-form-field-error-message";
import serverApi from "@api/core/serverApi";
import API_ENPOINTS from "@api/endpoints";

export const ResetPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams()
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const handleForgotPassword = (formData)=> {
 
      var token=searchParams.get('token');
      var uId=searchParams.get('uId');
      formData.userId=uId;
      formData.token=token;
      const response =  serverApi.post(API_ENPOINTS.auth.resetPassword, formData).then(res=>{
          setTimeout(() => {
            navigate("/login");
          }, 2000);
      });

    };
    return (
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer fluid className="h-custom">
          <CRow>
            <CCol md={8} className="p-0">
              <LoginSlogan />
            </CCol>
            <CCol md={4} className="p-0">
              <LoginFormContainer>
              <CForm onSubmit={handleSubmit(handleForgotPassword)} className="w-100">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="w-100 position-relative text-center">
                        <img src={companyLogo} className="img-fluid" />
                      </div>
                    </div>
                    <p className="text-medium-emphasis text-center p-4 text-center text-lighter font-weight-normal mt-5 mb-0 login-info">
                    <LocaleFormatter id="resetYourPassword" />
                    </p>
          
          
                    <CInputGroup className="mb-1">
                      <CFormInput
                        type="password"
                        placeholder={t({id:"password"})}
                        {...register("password", {
                          required: t({id:"passwordRequired"})
                        })}
                        autoComplete="false"
                      />
                    </CInputGroup>

                        <CInputGroup className="mt-3">
                      <CFormInput
                        type="password"
                        placeholder={t({id:"passwordConfirm"})}
                        {...register("confirmPassword", {
                          required: t({id:"passwordRequired"})
                        })}
                        autoComplete="false"
                      />
                    </CInputGroup>
                    <AppFormFieldErrorMessage message={errors?.email && errors.email.message}/> 
                    <CRow>
                      <CCol xs={12}>
                      <AppButton loading={loading} type="default" className="px-4 float-end" useSubmitBehavior={true} text={t({id:"resetPassword"})}></AppButton>
                      </CCol>
                    </CRow> 
                    <CRow>
                    <CCol xs={12} className="text-end mt-5">
                      <LocaleFormatter id="alreadyKnowYourPassword" />&nbsp;&nbsp;
                       <NavLink className="text-decoration-none" to="/login"><LocaleFormatter id="login" /></NavLink>
                      </CCol>
                    </CRow> 
                  </CForm>
              </LoginFormContainer>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
}
export default ResetPassword;

