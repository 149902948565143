import React from "react";
import { CFooter } from "@coreui/react";
import { useTranslation } from "locales";

const LoginSlogan = () => {
  const { t } = useTranslation();
  return (
    <div className="login-image">
      <div className="ui-bg-overlay"></div>
      <div className="text-white px-5 login-text">
        <h1 className="display-2  mb-3">AccuBus</h1>
        <div className="text-large font-weight-light text-justify">
          {t({ id: "loginSlogan" })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(LoginSlogan);
