import serverApi from "./serverApi";
import { logout } from "@stores/auth.slice";
import {
  saveAccessToken,
  getAccessToken,
  getRefreshToken,
  getLocale
} from "@services/core/localStorage.service";
import API_ENPOINTS from "@api/endpoints";
import { showError, showSuccess,showWarning } from "@services/core/notification.service";
import { setUserItem, UserState } from "@stores/user.slice";
import { decodeToken } from "@utils/jwtHelper";
import { ResultType } from "@interface/enums/resultTypeEnum";
const setup = store => {
  serverApi.interceptors.request.use(
    config => {
      const token = getAccessToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      const locale=getLocale();
      if(locale)
      {
        config.headers["Accept-Language"]=locale;
      }
      return config;
    },
    error => {
      Promise.reject(error);
    }
  );

  serverApi.interceptors.response.use(
    function(response) {
      if (response.data&&response.data.messages){
        if (response.data.messages.length > 0) {
          var message = response.data.messages.join("<br/>");
          if(response.data.status==ResultType.Success)
          {
            showSuccess(message);
          }else if(response.data.status==ResultType.Error)
          {
            showError(message);
          }else if(response.data.status==ResultType.Warning)
          {
            showWarning(message);
          }
        }
      }
      return response;
    },
    function(error) {
      if (error.response) {
        if (error.response.status == 400) {
          var data= error.response.data;
          var message = data.messages.join("<br/>");
          if(data.status==ResultType.Warning)
          {
            showWarning(message);
          }else
          {
            showError(message);
          }
        }
      }
      return Promise.reject(error);
    }
  );
  const { dispatch } = store;

  serverApi.interceptors.response.use(
    response => {
      return response;
    },
    async function(error) {
      const originalRequest = error.config;
      if (error.response) {
        if ((error.response.status === 401 || error.response.status===403) && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const res = await serverApi.post(API_ENPOINTS.auth.refreshToken, {
              refreshToken: getRefreshToken()
            });
            saveAccessToken(res.data.accessToken);
            var user = decodeToken<UserState>(res.data.accessToken);
            dispatch(setUserItem(user));
            serverApi.defaults.headers.common["Authorization"] =
              "Bearer " + res.data.accessToken;
            return serverApi(originalRequest);
          } catch (error) {
            dispatch(logout());
            return;
          }
        }
      }
      return Promise.reject(error);
    }
  );
};
export default setup;
