import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit/dist/createAction";
import { decodeToken } from "@utils/jwtHelper";

export interface UserState {
  userLocale: string;
  username: string;
  roles: string;
  fullName:string;
  photo:string;
}

export type Locale = "tr-TR" | "en-US";
var user=decodeToken<UserState>(localStorage.getItem("access_token"));
const initialState: UserState = {
  userLocale: (localStorage.getItem("locale")! || "en-US") as Locale,
  username: user?.username,
  roles:user?.roles,
  fullName:user?.fullName,
  photo:user?.photo
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserItem(state, action: PayloadAction<Partial<UserState>>) {
      Object.assign(state, action.payload);
    }
  }
});

export default userSlice.reducer;
export const { setUserItem } = userSlice.actions;
export const userActions = userSlice.actions;

