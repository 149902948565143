export default {
  auth: {
    login: "/auth/login",
    refreshToken: "/auth/refreshtokenlogin",
    tokenLogin:"/auth/tokenlogin",
    forgotPassword:"/auth/forgotPassword",
    resetPassword:"/auth/resetPassword",
  },
  school: {
    root: "/school",
    dataSource: "/school/datasource",
    schoolProfile: "/school/getSchoolProfile",
    updateSchoolProfile:"/school/updateSchoolProfie",
    downloadImportTeplateUrl:"/school/downloadImportTemplate",
    uploadTemplateUrl:"/school/uploadTemplate",
    importUrl:"/school/import"

  },
  auditLogs:{
    root: "/auditLogs",
    dataSource: "/auditLogs/datasource",
  },
  parent: {
    root: "/parent",
    dataSource: "/parent/datasource"
  },
  driver: {
    root: "/busdriver",
    dataSource: "/busdriver/datasource"
  },
  user: {
    changePassword: "/user/changepassword",
    userProfile:"/user/userProfile",
    updateUserProfile:"/user/updateUserProfile",

  },
  student: {
    root: "/student",
    dataSource: "/student/datasource"
  },
  schoolBus: {
    root: "/schoolBus",
    dataSource: "/schoolBus/datasource"
  },
  dashboard:{
    statistics:"/dashboard/statistics",
    tripStatistics:"/dashboard/tripStatistics",
    upComingSchoolTripsWidgetData:"/dashboard/upcomingSchoolTripsWidgetData"

  },
  studentGroup: {
    root: "/studentGroup",
    dataSource: "/studentGroup/datasource",
    studentGroupStudentsdataSource: "/studentgroup/studentgroupstudentsdatasource",

  },
  schoolTrip: {
    root: "/schoolTrip",
    dataSource: "/schoolTrip/datasource",
    details: "/schoolTrip/details",
    activeTrips:"/schoolTrip/active-trips",
    cancelTrip:"/schoolTrip/cancel-trip",
  },
  settings:{
    general:"/setting/generalSettings",
    saveGeneralSettings:"/setting/saveGeneralSettings",
    appearance:"/setting/appearanceSettings",
    saveAppearanceSettings:"/setting/saveAppearanceSettings"
  },
  timeZone:{
    root: "/timeZone",
    dataSource: "/timeZone/datasource",
  },
  qrCode:{
    generateQrCode:"/qrCode/generateQrCode"
  },
  busDepot: {
    root: "/busDepot",
    dataSource: "/busDepot/datasource"
  },
};
