export const appThemes=[
    {
        friendlyName:"Default",
        key:'light',
        devextremeTheme:'generic.light'
    },
    {
        friendlyName:"Carmine",
        key:'carmine',
        devextremeTheme:'generic.carmine'
    },
    {
        friendlyName:"Soft Blue",
        key:'softblue',
        devextremeTheme:'generic.softblue'
    },
    {
        friendlyName:"Greenmist",
        key:'greenmist',
        devextremeTheme:'generic.greenmist'
    },
    {
        friendlyName:"Material Blue",
        key:'material-blue-light',
        devextremeTheme:'material.blue.light.compact'
    },
    {
        friendlyName:"Material Orange",
        key:'material-orange-light',
        devextremeTheme:'material.orange.light.compact'
    },
    {
        friendlyName:"Material Purple",
        key:'material-purple-light',
        devextremeTheme:'material.purple.light.compact'
    },
];