import React from "react";
import { useTranslation, LocaleFormatter } from "locales";
import LoginSlogan from "@components/business/login/login.slogan";
import LoginFormContainer from "@components/business/login/login-form.container";

import {
  CContainer,
  CCol,
  CRow,
  CFormInput
} from "@coreui/react";
import "./forgot-password.scss";
import AppButton from "@components/core/form/controls/button/app-button";
import { RiMailSendFill } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
export const ForgotPasswordSucess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();


  return (
    <div
      className="bg-light min-vh-100 d-flex flex-row align-items-center"
      id="change-password"
    >
      <CContainer fluid className="h-custom">
        <CRow>
          <CCol md={8} className="p-0">
            <LoginSlogan />
          </CCol>
          <CCol md={4} className="p-0">
            <LoginFormContainer>
              <div className="flex-column">
                <div className="change-password-info">
                  <span className="title">
                    <RiMailSendFill
                      size={50}
                      className="change-password-sucess-icon"
                    />
                    <LocaleFormatter id="yourPasswordHasBeenSent" />
                  </span>
                  <span className="desc">
                    <LocaleFormatter
                      id="forgotPasswordEmailSuccessfullySent"
                      values={{ email: location.state.email }}
                    />
                  </span>
                </div>
                <div className="row">
                  <div className="change-password-success-form">
                    <div>
                      <p>
                        <LocaleFormatter id="ifEmailDoesNotAccessToYou" />
                      </p>
                      <ul>
                        <li>
                          <LocaleFormatter id="forgotPasswordInstructions1" />
                        </li>
                        <li>
                          <LocaleFormatter id="forgotPasswordInstructions2" />
                        </li>
                        <li>
                          <LocaleFormatter id="forgotPasswordInstructions3" />
                        </li>
                      </ul>
                    </div>
                    <br />
                    <AppButton
                      onClick={() => navigate("/login")}
                      type="default"
                      className="px-4 float-end w-100"
                      text={t({ id: "login" })}
                    ></AppButton>

                    <hr />
                    <AppButton
                      onClick={() => navigate("/forgot-password")}
                      type="default"
                      stylingMode="outlined"
                      className="px-4 float-end w-100 mt-2"
                      text={t({ id: "back" })}
                    ></AppButton>
                  </div>
                </div>
              </div>
            </LoginFormContainer>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};
export default ForgotPasswordSucess;
