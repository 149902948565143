import React from "react";

import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";

import { CAvatar } from "@coreui/react";
import { AppDispatch } from "@stores/index";
import { useDispatch } from "react-redux";
import { logout } from "@stores/auth.slice";
import { useTranslation } from "locales";
import { useNavigate } from "react-router";
import useAuth from "@hooks/userAuth";
import Restricted from "@components/core/shared/restricted";
import { Admin, SchoolAdmin } from "constants/roles";
import AppProfilePhoto from "@components/core/app-profile.photo";
const AppHeaderDropdown = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const user = useAuth();
  const navigate = useNavigate();
  return (
    <UncontrolledDropdown nav direction="down" className="px-3">
      <DropdownToggle nav>
        <AppProfilePhoto photo={user.photo}/>
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem
          onClick={() => {
            navigate("/profile");
          }}
        >
          <i className="fa-regular fa-user"></i>&nbsp; {t({ id: "manageProfile" })}
        </DropdownItem>
        <Restricted to={SchoolAdmin}>
          <DropdownItem
            onClick={() => {
              navigate("/school-profile");
            }}
          >
            <i className="fa-solid fa-house"></i> &nbsp; 
            {t({ id: "manageSchoolProfile" })}
          </DropdownItem>
        </Restricted>
        <DropdownItem
          onClick={() => {
            navigate("/change-password");
          }}
        >
          <i className="fa-solid fa-key"></i> &nbsp; 
          {t({ id: "changePassword" })}
        </DropdownItem>
        <DropdownItem className="text-danger"
          onClick={() => {
            dispatch(logout());
          }}
        >
          <i className="fa-solid fa-right-from-bracket"></i> &nbsp;  {t({ id: "logout" })}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default AppHeaderDropdown;
