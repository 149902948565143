import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import serverApi from "@api/core/serverApi";
import API_ENPOINTS from "@api/endpoints";
import {
  saveAccessToken,
  deleteAccessToken,
  getAccessToken,
  saveRefreshToken,
  deleteRefreshToken,
  getRefreshToken
} from "@services/core/localStorage.service";
import { setUserItem, UserState } from "./user.slice";
import { decodeToken } from "@utils/jwtHelper";
interface AuthState {
  isAuthenticated: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  loading: boolean;
}

const initialState: AuthState = {
  isAuthenticated: getAccessToken() != null,
  accessToken: getAccessToken(),
  refreshToken: getRefreshToken(),
  loading: false
};

export const login = createAsyncThunk(
  "auth/login",
  async ({
    usernameOrEmail,
    password
  }: {
    usernameOrEmail: string;
    password: string;
  }, { dispatch }) => {
    const response = await serverApi.post(API_ENPOINTS.auth.login, {
      usernameOrEmail,
      password
    });
    var user= decodeToken<UserState>(response.data.accessToken);
    dispatch(setUserItem(user));
    return response.data;
  }
);
export const refreshToken = createAsyncThunk(
  "auth/refreshToken",
  async (refreshToken: string, { dispatch }) => {
    const response = await serverApi.post(API_ENPOINTS.auth.refreshToken, {
      refreshToken: refreshToken
    });
    saveAccessToken(response.data.accessToken);
    var user = decodeToken<UserState>(response.data.accessToken);
    dispatch(setUserItem(user));
    return response.data;
  }
);
export const logout = createAsyncThunk("auth/logout", async () => {
  deleteAccessToken();
  deleteRefreshToken();
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(login.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      saveAccessToken(action.payload.accessToken);
      saveRefreshToken(action.payload.refreshToken);
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.isAuthenticated = false;
      state.accessToken = null;
      state.refreshToken = null;
      state.loading = false;
    });
  }
});

export default authSlice.reducer;
