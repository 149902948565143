import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import React from "react";
import UnAuthorizedRoute from "./un-authorized-route";
import Login from "pages/login/login";
import ForgotPassword from "pages/forgot-password/forgot-password";
import ForgotPasswordSucess from "pages/forgot-password/forgot-password-sucess";
import ResetPassword from "pages/reset-password/reset-password";
import DefaultLayout from "@layout/default-layout";
const AppRoutes = () => {
  return (
        <Routes>
          <Route
            path="/login"
            element={
              <UnAuthorizedRoute>
                <Login />
              </UnAuthorizedRoute>
            }
          />

          <Route
            path="/forgot-password"
            element={
              <UnAuthorizedRoute>
                <ForgotPassword />
              </UnAuthorizedRoute>
            }
          />

          <Route
            path="/forgot-password-success"
            element={
              <UnAuthorizedRoute>
                <ForgotPasswordSucess />
              </UnAuthorizedRoute>
            }
          />
          <Route
            path="/reset-password"
            element={
              <UnAuthorizedRoute>
                <ResetPassword />
              </UnAuthorizedRoute>
            }
          />
          <Route path="*" element={<DefaultLayout />} />
        </Routes>

  );
};
export default AppRoutes;