import React, { useRef, useEffect, useState, useMemo } from "react";
import { Card } from "reactstrap";
import AppMap from "@components/core/form/controls/map/app-map";
import { LatLngExpression } from "leaflet";
import { busIcon } from "@components/common/busIcon";
import "react-sliding-side-panel/lib/index.css";
import { Marker, Popup } from "react-leaflet";
import { signalrService } from "@services/core/signalrService";
import { getActiveTrips } from "@services/business/school-trip.service";
import ActiveSchoolTripTable from "@components/business/live-map/active-school-trip-table/active-school-trip-table";
import { useParams } from "react-router-dom";

const SchoolTripSimulation = () => {
    const { id } = useParams();
  let conn = null;

  const sampleMapArray: Array<LatLngExpression> = [
    [28.65468608717766, -81.24399173681454]
  ];
  const [markerPosition, setMarkerPosition] = useState(sampleMapArray[0]);
  const markerRef = useRef(null);
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    var signalRConnection = signalrService.startConnection("location-hub");

    signalRConnection.then((con) => {
      conn = con;
      setConnection(con);
      conn.invoke("Join", id);
    });

    return () => {
      if (conn) conn.stop();
    };
  }, []);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setMarkerPosition(marker.getLatLng());
          var res = marker.getLatLng();
          conn.invoke("locationUpdated", {
            Latitude: res.lat,
            Longitude: res.lng,
            TripId: id,
          });
        }
      },
    }),
    []
  );

  return (
    <React.Fragment>

      <Card className="list-grid">
        <AppMap center={markerPosition}>
          <Marker
            position={markerPosition}
            icon={busIcon}
            draggable={true}
            eventHandlers={eventHandlers}
            ref={markerRef}
          ></Marker>
        </AppMap>
      </Card>
    </React.Fragment>
  );
};
export default SchoolTripSimulation;
