import React from "react";
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow
} from "@coreui/react";
import { useDispatch } from "react-redux";
import { login } from "@stores/auth.slice";
import { AppDispatch } from "@stores/index";
import companyLogo from "@assets/images/company-logo.svg";
import { useForm } from "react-hook-form";
import AppFormFieldErrorMessage from "@components/core/form/app-form-field-error-message";
import AppButton from "@components/core/form/controls/button/app-button";
import LoginSlogan from "@components/business/login/login.slogan";
import LoginFormContainer from "@components/business/login/login-form.container";

import { useAppSelector } from "@hooks/useAppHooks";
import { LocaleFormatter, useTranslation } from "locales";
import { NavLink } from "react-router-dom";
const Login = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useAppSelector(x => x.auth);
  const handleLogin = (formData)=> {
    dispatch(login(formData)).unwrap();
  };
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer fluid className="h-custom">
        <CRow>
          <CCol md={8} className="p-0 login-slogan-container">
           <LoginSlogan/>
          </CCol>
          <CCol md={4} className="p-0">
            <LoginFormContainer>
                  <CForm onSubmit={handleSubmit(handleLogin)} className="w-100">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="w-100 position-relative text-center">
                        <img src={companyLogo} className="img-fluid" />
                      </div>
                    </div>
                    <p className="text-medium-emphasis text-center p-4 text-center text-lighter font-weight-normal mt-5 mb-0 login-info">
                    <LocaleFormatter id="loginToYourAccount" />
                    </p>
                    <CInputGroup className={["mb-1", errors ?.usernameOrEmail ? "has-error" : ""].join(" ")}>
                      <CFormInput
                        
                        placeholder={t({id:"username"})}
                        autoComplete="username"
                        {...register("usernameOrEmail", {
                          required: t({id:"userNameRequired"})
                        })}
                        
                      />
                    </CInputGroup>
                    <AppFormFieldErrorMessage message={errors?.usernameOrEmail && errors.usernameOrEmail.message}/> 
                    <CInputGroup className="mb-1 mt-2">
                      <CFormInput
                        type="password"
                        placeholder={t({id:"password"})}
                        {...register("password", {
                          required: t({id:"passwordRequired"})
                        })}
                        autoComplete="current-password"
                      />
                    </CInputGroup>
                    <CCol xs={6} className="p-1">
                    <AppFormFieldErrorMessage message={errors?.password && errors.password.message}/> 
                    <NavLink to="/forgot-password" className="text-decoration-none semi-bold text-primary" ><LocaleFormatter id="forgotPassword" /></NavLink></CCol>

                    <CRow>
                      <CCol xs={12}>
                      <AppButton loading={loading} type="default" className="px-4 float-end" useSubmitBehavior={true} text={t({id:"login"})}></AppButton>
                      </CCol>
                    </CRow> 
                  </CForm>
               </LoginFormContainer>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
