import SelectBox from "devextreme-react/select-box";
import React, { FC, useEffect, useState, useRef, Ref } from "react";
import { IAppFormControlProps } from "@interface/common/controls/app-control.props";
import L, { LatLngExpression } from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import "leaflet-fullscreen/dist/Leaflet.fullscreen.js";
export interface IAppMapProps extends IAppFormControlProps {
  center?: LatLngExpression;
  children?: any;
  onLoad?: (map) => void;
  zoom?:any;
}
const AppMap: FC<IAppMapProps> = ({ center, children,onLoad,zoom }) => {
  const [map, setMap] = useState(null);
 
  return (
    <React.Fragment>
      <MapContainer
        fullscreenControl={true}
      
        ref={comp => {
          if(comp)
          {
            setMap(comp);
            if(onLoad)
            onLoad(comp);
          }
        }}
        attributionControl={false}
        center={center}
        zoom={zoom}
        style={{ width: "100%", height: "800px" }}
      >
        <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
        {children}
      </MapContainer>
    </React.Fragment>
  );
};
export default AppMap;
AppMap.defaultProps={
  zoom:6
}