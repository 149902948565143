import { Navigate } from 'react-router-dom';

import React from 'react';
import { useAppSelector } from '@hooks/useAppHooks';
function UnAuthorizedRoute({ children }) {
    const authUser = useAppSelector(x => x.auth);
    
    if (authUser.isAuthenticated) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/" />
    }

  
    return children;
}

export default UnAuthorizedRoute;