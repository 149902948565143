import React from "react";
import {  CCardGroup, CCard, CCardBody } from "@coreui/react";

const LoginFormContainer = props => {
  return (
    <CCardGroup className="d-flex flex-row align-items-center justify-content-center login-container">
      <CCard className="p-4 h-100">
        <CCardBody className="d-flex flex-row align-items-center justify-content-center">
          {props.children}
        </CCardBody>
      </CCard>
    </CCardGroup>
  );
};

export default React.memo(LoginFormContainer);
