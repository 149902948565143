import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import store from "./store";
import { Provider } from "react-redux";
import setupInterceptors from "@api/core/setupInterceptors";
const root = ReactDOM.createRoot(document.getElementById(
  "root"
) as HTMLElement);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

setupInterceptors(store);
