import React from 'react'
import AppSidebar from './app-sidebar'
import AppHeader from './header/app-header'
import AppContent from './app-content'
import AppFooter from './app-footer'

const DefaultLayout = () => {
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <AppContent />
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  )
}

export default DefaultLayout
