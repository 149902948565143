import { Navigate } from 'react-router-dom';

import React from 'react';
import { useAppSelector } from '@hooks/useAppHooks';
function PrivateRoute({ children }) {
    const authUser = useAppSelector(x => x.auth);
    if (!authUser.isAuthenticated) {
        return <Navigate to="/login" />
    }else
    {
        return children;
    }

}

export default PrivateRoute;