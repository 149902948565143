import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import serverApi from "@api/core/serverApi";
import API_ENPOINTS from "@api/endpoints";

interface LiveMapState {
  activeTrips: Array<any>;
  loading: boolean;
}

const initialState: LiveMapState = {
  activeTrips: [],
  loading:false
};

export const loadActiveTrips = createAsyncThunk(
  "live/loadActiveTrips",
  async (_, { dispatch }) => {
    const response = await serverApi.get(API_ENPOINTS.schoolTrip.activeTrips);
    return response.data;
  }
);

const liveMapSlice = createSlice({
  name: "liveMap",
  initialState,
  reducers: {
    setActiveTrips(state, action) {
      state.activeTrips = action.payload;
    }
  }, extraReducers: builder => {
    builder.addCase(loadActiveTrips.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loadActiveTrips.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(loadActiveTrips.fulfilled, (state, action) => {
      state.loading = false;
      state.activeTrips=action.payload;
    });
   
  }
});

export default liveMapSlice.reducer;
export const { setActiveTrips } = liveMapSlice.actions;
export const liveMapActions = liveMapSlice.actions;

