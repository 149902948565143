import React, { FC } from "react";
import Button from "devextreme-react/button";
import { LoadIndicator } from "devextreme-react/load-indicator";
import './app-button.scss'
import { ButtonStyle } from "devextreme/common";
export interface IAppButtonProps {
  className?: string;
  type: "back" | "danger" | "default" | "normal" | "success";
  onClick?: (e) => void;
  loading?: boolean;
  disabled?: boolean;
  text?: string;
  icon?: any;
  iconClass?:any;
  useSubmitBehavior?: boolean;
  stylingMode?: ButtonStyle;
  hint?:string;
}
const AppButton: FC<IAppButtonProps> = ({
  className,
  onClick,
  loading,
  disabled,
  text,
  icon,
  iconClass,
  useSubmitBehavior,
  type,
  stylingMode,
  hint
}) => {
  let buttonIcon = `dx-icon dx-icon-${icon}`;
  className+=" app-buttton";
  if(iconClass)
  {
    buttonIcon=iconClass+" custom-button-icon";
  }

  if(icon&&text)
  {
    className+=" btn-with-icon-and-text";
  }

  const handleOnClick=(e)=>{
    onClick(e);
  }
  return (
    <Button
      className={className}
      disabled={loading}
      type={type}
      stylingMode={stylingMode}
      hint={hint}
      onClick={e => {
        if (onClick) handleOnClick(e);
      }}
      useSubmitBehavior={useSubmitBehavior}
    >
      <LoadIndicator className="button-indicator" visible={loading} width={15} height={15} />
      {(icon||iconClass) && <i className={buttonIcon}></i>}
      {text && <span className="dx-button-text">{text}</span>}
    </Button>
  );
};
export default AppButton;