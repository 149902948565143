export const trTR = {
  home:"Anasayfa",
  drivers:"Sürücüler",
  parents:"Veliler",
  new:"Yeni",
  refresh:"Yenile",
  schools: "Okullar",
  name:"İsim",
  createNewSchool:"Yeni Okul",
  editSchool:"Okul Düzenle",
  edit:"Düzenle",
  create:"Oluştur",
  save:"Kaydet",
  cancel:"İptal",
  username:"Kullanıcı Adı",
  password:"Şifre",
  login:"Giriş Yap",
  loginToYourAccount:"Hesabınıza Giriş Yapın",
  loginSlogan:"Piyasadaki en akıllı çözüm !",
  manageProfile:"Profil Düzenle",
  changePassword:"Şifre Değiştir",
  logout:"Çıkış Yap",
  deleteConfirmationMessage:"Bu kayıtı silmek istediğinize emin misiniz?",
  yes:"Evet",
  dashboard:"Gösterge Paneli",
  userNameRequired:"Kullanıcı adı gereklidir",
  passwordRequired:"Şifre gereklidir",
  emailRequired:"Email gereklidir",
  back:"Geri",
  createNewParent:"Yeni Veli",
  editParent:"Veli Düzenle",
  surname:"Soyisim",
  location:"Kordinat",
  phoneNumber:"Telefon Numarası",
  email:"Email",
  fullname:"İsim Soyisim",
  students:"Öğrenciler",
  number:"No",
  driverLocation:"Sürücü Kordinatı",
  map:"Harita",
  driverLicence:"Sürücü Ehliyet",
  createNewDriver:"Yeni Sürücü",
  editDriver:"Sürücü Düzenle",
  schoolName:"Okul Adı",
  currentPassword:"Mevcut Şifre",
  newPassword:"Yeni Şifre",
  newPasswordConfirm:"Yeni Şifre Onay",
  student:"Öğrenci",
  plateNumber:"Plaka Numarası",
  schoolBuses:"Okul Otobüsleri",
  createSchoolBus:"Okul Otobüsü Oluştur",
  editSchoolBus:"Okul Otobüsü Düzenle",
  busDriver:"Şöför",
  profile: "Profil",
  update: "Güncelle",
  photo:"Fotoğraf",
  export:"Dışa Aktar",
  liveMap:"Harita",
  driver:"Sürücü",
  welcomeBack:"Tekrar hoşgeldin",
  schoolProfile:"Okul Profili",
  manageSchoolProfile:"Okul Profili Güncelle",
  delete:"Sil",
  advancedSearch:"Gelişmiş Arama",
  schoolInformation:"Okul Bilgisi",
  userInformation:"Kullanıcı Bilgisi",
  schoolBusInformation:"Okul Otobüsü Bilgisi",
  studentInformation:"Öğrenci Bilgisi",
  drivingLicense:"Sürücü Lisansı",
  import:"İçe Aktar",
  downloadImportTemplate:"Sizin için hazırladığımız Excel şablonunu indirin.",
  importYourData:"Verilerinizi şablona aktarın.",
  saveImportTemplate:"Şablonu kaydedin ve buradan yeniden yükleyin.",
  uploadTemplate:"Şablonu Yükle",
  total:"Toplam",
  valid:"Geçerli",
  invalid:"Geçersiz",
  PleaseWaitWhileProcessingYourData:"Verileriniz işlenirken lütfen bekleyin",
  downloadTemplate:"Taslağı İndir",
  close:"Kapat",
  resetYourPassword:"Şifrenizi Yenileyin",
  resetPasswordInstructions:"Kayıtlı e-posta adresinizi girin, size şifrenizi sıfırlamak için bir bağlantı göndereceğiz.",
  sendPasswordResetEmail:"Parola sıfırlama e-postası gönder",
  passwordConfirm:"Şifre Doğrulama",
  resetPassword:"Şifre Yenileme",
  alreadyKnowYourPassword:"Zaten şifrenizi biliyor musunuz?",
  forgotPassword:"Şifreminizi mi Unuttunuz?",
  forgotPasswordEmailSuccessfullySent:"{email} adresine şifre yenileme linkiniz gönderildi.",
  yourPasswordHasBeenSent:"Şifreniz Gönderildi",
  ifEmailDoesNotAccessToYou:"Şifre Yenileme e-postası elinize ulaşmadıysa:",
  forgotPasswordInstructions1:"Şifre yenileme e-postasının gelmesi 10 dakikayı bulabilir.",
  forgotPasswordInstructions2:"Spam ve diğer e-posta klasörlerinizi kontrol ediniz.",
  forgotPasswordInstructions3:"  Girdiğiniz e-posta adresinin doğruluğunu kontrol ediniz.",
  studentGroups: "Öğrenci Grupları",
  createStudentGroup: "Öğrenci Grubu Oluşur",
  editStudentGroup: "Öğrenci Grubu Düzenle",
  studentGroupInformation:"Öğrenci Grubu Bilgisi",
  isActive:"Aktif",
  numberOfStudents:"# Öğrenci",
  active:"Aktif",
  inActive:"Pasif",
  color:"Renk",
  capacity:"Kapasite",
  schoolTrips:"Okul Gezileri",
  createSchoolTrip:"Okul Gezisi Oluştur",
  editSchoolTrip:"Okul Gezisi Düzenle",
  tripInformation:"Gezi Bilgileri",
  startDate:"Başlangıç Tarihi",
  endDate:"Bitiş Tarihi",
  schoolBus:"Okul Otobüsü",
  from:"Nereden",
  to:"Nereye",
  clone:"Kopyala",
  cloneSchoolTrip:"Okul Gezisi Kopyala",
  upComingTrips:"Yaklaşan Geziler",
  tripStatistics:"Gezi İstatistikleri",
  settings:"Ayarlar",
  general:"Genel",
  generalSettings:"Genel Ayarlar",
  timezone:"Zaman Dilimi",
  appearance:"Görünüm Ayarları",
  theme:"Tema",
  view:"Görüntüle",
  tripSettings:"Gezi Ayarları",
  clickToBrowseFile:"Bir dosya seçmek için tıklayın.",
  latitude:"Latitude",
  longitude:"Longitude",
  cropResizeImage:"Görüntüyü Kırp/Yeniden Boyutlandır",
  crop:"Kırp",
  qrCode:"Qr Code",
  remarks:"Notlar",
  parent:"Veli",
  english:"İngilizce",
  turkish:"Türkçe",
  useMyCurrentLocation:"Şu anki Konumumu Kullan",
  remove:"Sil",
  change:"Değiştir",
  auditLogs:"Denetim Günlükleri",
  date:"Tarih",
  user:"Kullanıcı",
  entityType:"Record Türü",
  action:"İşlem",
  entityId:"Kayıt No",
  details:"Detaylar",
  auditLog:"Log Detayı",
  school:"Okul",
  createNewStudent:"Yeni Öğrenci",
  editStudent:"Öğrenci Düzenle",
  departureTime:"Hareket Saati",
  arrivalTime:"Varış Zamanı",
  stops:"Duraklar",
  addABusStop:"Geziye Bir Durak Ekle",
  editBusStop:"Durağı Düzenle",
  stopInformation:"Durak Bilgileri",
  stop:"Durak",
  between:"Arasında",
  list:"Liste",
  tripDetails:"Gezi Detayları",
  stayTime:"Konaklama Süresi",
  startLocation:"Başlangıç Lokasyonu",
  endLocation:"Bitiş Lokasyonu",
  busDepots:"Otobüs Terminalleri",
  address:"Adres",
  busDepot:"Otobüs Terminali",
  numberOfBuses:"# Otobüs",
  web:"Web Sitesi" ,
  busNumber:"Otobüs Numarası",
  activeTrips:"Aktif Geziler",
  status:"Statü",
  cancelConfirmationMessage: "Bu geziyi iptal etmek istediğinize emin misiniz?",
  no:"Hayır"
};
