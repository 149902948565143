import React from "react";

import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import { CSSTransition } from "react-transition-group";
import { AppDispatch } from "@stores/index";
import { useDispatch } from "react-redux";
import { AiOutlineGlobal } from "react-icons/ai";
import { setUserItem } from "@stores/user.slice";
import { useTranslation } from "locales";
import { useAppSelector } from "@hooks/useAppHooks";
const AppHeaderLanguageSelectorDropdown = () => {
  const { t } = useTranslation();
  const { userLocale } = useAppSelector(x => x.user);

  const dispatch = useDispatch<AppDispatch>();
  const selectLocale = ({ key }: { key: any }) => {
    if(userLocale!=key)
    {
      dispatch(setUserItem({ locale: key }));
      localStorage.setItem("locale", key);
      window.location.reload();
    }
   
  };
  return (
    <UncontrolledDropdown nav direction="down" className="px-3">
      <DropdownToggle nav className="animate language-selector">
        <i className="fa-solid fa-language"></i>
      </DropdownToggle>
      <DropdownMenu right>
        <CSSTransition
          in={true}
          timeout={500}
          classNames="menu-primary"
          unmountOnExit
        >
          <DropdownItem
            onClick={() => {
              selectLocale({ key: "en-US" });
            }}
          >
            {userLocale == "en-US" ? (
              <>
                <span className="text-success">
                  <i className="fa-solid fa-circle-check"></i>
                </span>{" "}
                &nbsp;&nbsp;
              </>
            ) : (
              <span className="language-selector-space"></span>
            )}
            {t({ id: "english" })}
          </DropdownItem>
        </CSSTransition>
        <CSSTransition
          in={true}
          timeout={500}
          classNames="menu-primary"
          unmountOnExit
        >
          <DropdownItem
            onClick={() => {
              selectLocale({ key: "tr-TR" });
            }}
          >
            {userLocale == "tr-TR" ? (
              <>
                <span className="text-success">
                  <i className="fa-solid fa-circle-check"></i>
                </span>
                &nbsp;&nbsp;
              </>
            ) : (
              <span className="language-selector-space"></span>
            )}
            {t({ id: "turkish" })}
          </DropdownItem>
        </CSSTransition>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default AppHeaderLanguageSelectorDropdown;
