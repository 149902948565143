import React from "react";
import CIcon from "@coreui/icons-react";

import {
  RiDashboard3Line,
  RiGroupLine,
  RiCommunityLine,
  RiUserLocationLine,
  RiBusLine,
  RiMapPin2Line,
  RiGroup2Fill,
  RiRouteFill,
  RiSettings3Line,
  RiFileList3Line,
  RiTeamLine,
  RiBuilding4Line
} from "react-icons/ri";

import { CNavItem } from "@coreui/react";
import { Admin, SchoolAdmin } from "constants/roles";
const _nav = [
  {
    component: CNavItem,
    name: "dashboard",
    to: "/dashboard",
    icon: <RiDashboard3Line className="nav-icon" />,
    roles: [Admin, SchoolAdmin]
  },
  {
    component: CNavItem,
    name: "liveMap",
    to: "/live-map",
    icon: <RiMapPin2Line className="nav-icon" />,
    roles: [SchoolAdmin]
  },
  {
    component: CNavItem,
    name: "schools",
    to: "/schools",
    icon: <RiCommunityLine className="nav-icon" />,
    roles: [Admin]
  },
  {
    component: CNavItem,
    name: "auditLogs",
    to: "/audit-logs",
    icon: <RiFileList3Line className="nav-icon" />,
    roles: [Admin]
  },
  {
    component: CNavItem,
    name: "studentGroups",
    to: "/rider-groups",
    icon: <RiGroup2Fill className="nav-icon" />,
    roles: [SchoolAdmin]
  },
  {
    component: CNavItem,
    name: "schoolTrips",
    to: "/school-trips",
    icon: <RiRouteFill className="nav-icon" />,
    roles: [SchoolAdmin]
  },
  {
    component: CNavItem,
    name: "schoolBuses",
    to: "/school-buses",
    icon: <RiBusLine className="nav-icon" />,
    roles: [SchoolAdmin]
  },
  {
    component: CNavItem,
    name: "busDepots",
    to: "/bus-depots",
    icon: <RiBuilding4Line className="nav-icon" />,
    roles: [SchoolAdmin]
  },
  {
    component: CNavItem,
    name: "students",
    to: "/students",
    icon: <RiTeamLine className="nav-icon" />,
    roles: [SchoolAdmin]
  },
  {
    component: CNavItem,
    name: "drivers",
    to: "/drivers",
    icon: <RiUserLocationLine className="nav-icon" />,
    roles: [SchoolAdmin]
  },
  {
    component: CNavItem,
    name: "parents",
    to: "/parents",
    icon: <RiGroupLine className="nav-icon" />,
    roles: [SchoolAdmin]
  },
  {
    component: CNavItem,
    name: "settings",
    to: "/settings",
    icon: <RiSettings3Line className="nav-icon" />,
    roles: [SchoolAdmin]
  }
];

export default _nav;
