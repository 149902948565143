import Swal from "sweetalert2";

export const showSuccess = (message: string): void => {
  Swal.fire({
    position: "top-end",
    icon: "success",
    html: message,
    showConfirmButton: false,
    timer: 4500,
    toast: true,
    timerProgressBar:true
  });
};

export const showError = (message: string): void => {
  if(message&&message!==''){
    Swal.fire({
      position: "top-end",
      icon: "error",
      html: message,
      showConfirmButton: false,
      timer: 4500
    });
  }
};

export const showWarning = (message: string): void => {
  if(message&&message!==''){
    Swal.fire({
      position: "top-end",
      icon: "warning",
      html: message,
      showConfirmButton: false,
      timer: 4500,
      toast: true,
      timerProgressBar:true
    });
  }
};

export const showConfirmation = (
  message: string,
  confirmButtonText: string,
  cancelButtonText: string,
  successCallback: Function,
  cancelCallback?: Function
): void => {
  Swal.fire({
    html: message,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText
  }).then(result => {
    if (result.isConfirmed) {
      successCallback();
    } else {
      if (cancelCallback) {
        cancelCallback();
      }
    }
  });
};
