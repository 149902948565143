export const enUS = {
  home: "Home",
  drivers: "Drivers",
  parents: "Parents",
  new: "New",
  refresh: "Refresh",
  schools: "Schools",
  name: "Name",
  createNewSchool: "Create a New School",
  editSchool: "Edit School",
  edit: "Edit",
  create: "Create",
  save: "Save",
  cancel: "Cancel",
  username: "Username",
  password: "Password",
  login: "Login",
  loginToYourAccount: "Login to Your Account",
  loginSlogan: "The smartest solution on the market!",
  manageProfile: "Manage Profile",
  changePassword: "Change Password",
  logout: "Logout",
  deleteConfirmationMessage: "Are you sure you want to delete this record?",
  yes: "Yes",
  dashboard: "Dashboard",
  userNameRequired: "Username is required.",
  passwordRequired: "Password is required",
  emailRequired:"Email is required",
  back: "Back",
  createNewParent: "Create a New Parent",
  editParent: "Edit Parent",
  surname: "Surname",
  location: "Location",
  phoneNumber: "Phone Number",
  email: "Email",
  fullname: "Full Name",
  driverLicence: "Driver Licence",
  createNewDriver: "Create New Driver",
  editDriver: "Edit Driver",
  students: "Students",
  number: "Number",
  driverLocation: "Driver Location",
  map: "Map",
  schoolName: "School Name",
  currentPassword: "Current Password",
  newPassword: "New Password",
  newPasswordConfirm: "New Password Confirm",
  student: "Student",
  plateNumber: "Plate Number",
  schoolBuses: "School Buses",
  createSchoolBus: "Create School Bus",
  editSchoolBus: "Edit School Bus",
  busDriver: "Bus Driver",
  profile: "Profile",
  update: "Update",
  photo:"Photo",
  export:"Export",
  liveMap:"Live Map",
  driver:"Driver",
  welcomeBack:"Welcome Back",
  schoolProfile:"School Profile",
  manageSchoolProfile:"Manage School Profile",
  delete:"Delete",
  advancedSearch:"Advanced Search",
  schoolInformation:"School Information",
  userInformation:"User Information",
  schoolBusInformation:"School Bus Information",
  studentInformation:"Student Information",
  drivingLicense:"Driving License",
  import:"Import",
  downloadImportTemplate:"Download the Excel template we prepared for you.",
  importYourData:"Import your data into the template.",
  saveImportTemplate:"Save the template and upload it here.",
  uploadTemplate:"Upload Template",
  total:"Total",
  valid:"Valid",
  invalid:"Invalid",
  PleaseWaitWhileProcessingYourData:"Please wait while processing your data",
  downloadTemplate:"Download Template",
  close:"Close",
  resetYourPassword:"Reset Your Password",
  resetPasswordInstructions:"Enter your registered email address and we will send you a link to reset your password.",
  sendPasswordResetEmail:"Send password reset email",
  passwordConfirm:"Password Confirm",
  resetPassword:"Reset Password",
  alreadyKnowYourPassword:"Already know your password?",
  forgotPassword:"Forgot your password?",
  forgotPasswordEmailSuccessfullySent:"Your password reset link has been sent to {email}.",
  yourPasswordHasBeenSent:"Your Password Has Been Sent",
  ifEmailDoesNotAccessToYou:"If you did not receive the Password Reset email:",
  forgotPasswordInstructions1:"It may take up to 10 minutes for the password reset email to receive.",
  forgotPasswordInstructions2:"Check your spam and other e-mail folders.",
  forgotPasswordInstructions3:"Check that the e-mail address you entered is correct.",
  studentGroups: "Rider Groups",
  createStudentGroup: "Create Rider Group",
  editStudentGroup: "Edit Rider Group",
  studentGroupInformation:"Rider Group Information",
  isActive:"Active",
  numberOfStudents:"# Students",
  active:"Active",
  inActive:"Inactive",
  color:"Color",
  capacity:"Capacity",
  schoolTrips:"School Trips",
  createSchoolTrip:"Create School Trip",
  editSchoolTrip:"Edit School Trip",
  tripInformation:"Trip Information",
  startDate:"Begin Date",
  endDate:"End Date",
  schoolBus:"School Bus",
  from:"From",
  to:"To",
  clone:"Clone",
  cloneSchoolTrip:"Clone School Trip",
  upComingTrips:"Upcoming Trips",
  tripStatistics:"Trip Statistics",
  settings:"Settings",
  general:"General",
  generalSettings:"General Settings",
  timezone:"Timezone",
  appearance:"Appearance",
  theme:"Theme",
  view:"View",
  tripSettings:"Trip Settings",
  clickToBrowseFile:"Click to browse for a file.",
  latitude:"Latitude",
  longitude:"Longitude",
  cropResizeImage:"Crop/Resize Image",
  crop:"Crop",
  qrCode:"Qr Code",
  remarks:"Remarks",
  parent:"Parent",
  english:"English",
  turkish:"Turkish",
  useMyCurrentLocation:"Use My Current Location",
  auditLogs:"Audit Logs",
  date:"Date",
  user:"User",
  entityType:"Entity",
  action:"Action",
  entityId:"Entity ID",
  details:"Details",
  auditLog:"Audit Log Detail",
  school:"School",
  remove:"Remove",
  change:"Change",
  createNewStudent:"Create New Student",
  editStudent:"Edit Student",
  departureTime:"Departure Time",
  arrivalTime:"Arrival Time",
  stops:"Stops",
  addABusStop:"Add A Stop To School Trip",
  editBusStop:"Edit Stop",
  stopInformation:"Stop Information",
  stop:"Stop",
  between:"Between",
  list:"List",
  tripDetails:"Trip Details",
  stayTime:"Stay Time",
  startLocation:"Begin Location",
  endLocation:"End Location",
  busDepots:"Bus Depots",
  createBusDepot: "Create Bus Depot",
  editBusDepot: "Edit Bus Depot",
  busDepotInformation:"Bus Depot Information",
  address:"Address",
  busDepot:"Bus Depot",
  numberOfBuses:"# Buses"  ,
  web:"WebSite",
  busNumber:"Bus Number",
  activeTrips:"Active Trips",
  status:"Status",
  cancelConfirmationMessage: "Are you sure you want to cancel this trip?",
  no:"No"

};
