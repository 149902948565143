import L from "leaflet";
import iconUrl from "@assets/images/bus.png";
import shadowUrl from "@assets/images/marker-shadow.png";


const {
  shadowSize,
  tooltipAnchor
} = L.Marker.prototype.getIcon().options;

export const busIcon = L.icon({
  iconUrl,
  shadowUrl,
  shadowSize,
  tooltipAnchor,
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});


