import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import serverApi from "@api/core/serverApi";
import API_ENPOINTS from "@api/endpoints";
import { saveTheme, getTheme } from "@services/core/localStorage.service";

interface AppState {
  sidebarShow: boolean;
  unfoldable: boolean;
  theme: string;
}

const initialState: AppState = {
  sidebarShow: true,
  unfoldable: false,
  theme: getTheme()
};

export const loadAppearenceSettings = createAsyncThunk(
  "app/loadAppearenceSettings",
  async (_, { dispatch }) => {
    const response = await serverApi.get(API_ENPOINTS.settings.appearance);
    dispatch(setAppearanceSettings(response.data.theme));
    return response.data;
  }
);

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleSideBar: (state, action) => {
      state.sidebarShow = action.payload;
    },
    toggleUnfoldable: (state, action) => {
      state.unfoldable = action.payload;
    },
    setAppearanceSettings(state, action) {
      state.theme = action.payload;
      saveTheme(state.theme);
    }
  }
});

export default appSlice.reducer;
export const { setAppearanceSettings } = appSlice.actions;
export const appActions = appSlice.actions;
