import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CBreadcrumb, CBreadcrumbItem } from "@coreui/react";
import routes from "@navigation/routes";
import { useTranslation } from "locales";

const AppBreadcrumb = () => {
  const currentLocation = useLocation().pathname;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getRouteName = (pathname, routes) => {
    const currentRoute = routes.sort(route=>route.length).filter(route => route.path.startsWith(pathname))[0];
    return currentRoute ? currentRoute.name : false;
  };
  const getBreadcrumbs = location => {
    const breadcrumbs: Array<any> = [];
    location.split("/").reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`;
      const routeName = getRouteName(currentPathname, routes);
      routeName &&
        breadcrumbs.push({
          pathname: currentPathname,
          name: routeName,
          active: index + 1 === array.length ? true : false
        });
      return currentPathname;
    });
    return breadcrumbs;
  };

  const breadcrumbs = getBreadcrumbs(currentLocation);

  return (
    <CBreadcrumb className="m-0 ms-2">
      <CBreadcrumbItem onClick={() => navigate("/")}>{t({id:'home'})}</CBreadcrumbItem>
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <CBreadcrumbItem
            {...(breadcrumb.active
              ? { active: true }
              : { onClick: () => navigate(breadcrumb.pathname) })}
            key={index}
          >
            {t({id:breadcrumb.name})}
          </CBreadcrumbItem>
        );
      })}
    </CBreadcrumb>
  );
};

export default React.memo(AppBreadcrumb);
