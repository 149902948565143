import { FC } from "react";
import { MessageDescriptor } from "react-intl";

import { FormattedMessage, useIntl } from "react-intl";

import { enUS } from "./en-US";
import { trTR } from "./tr-TR";
import React from "react";

export const localeConfig = {
  tr_TR: trTR,
  en_US: enUS
};

export type Id = keyof typeof enUS;

interface Props extends MessageDescriptor {
  id: Id;
  values?: any;
}

export const LocaleFormatter: FC<Props> = ({ ...props }) => {
  const notChildProps = { ...props, children: undefined };

  return (
    <FormattedMessage {...notChildProps} id={props.id} />
  );
};

type FormatMessageProps = (descriptor: Props) => string;

export const useTranslation = () => {
  const { formatMessage: _formatMessage, ...rest } = useIntl();
  const t: FormatMessageProps = _formatMessage;
  
  return {
    ...rest,
    t
  };
};
