import SchoolTripSimulation from "pages/school-trip/simulation";
import React from "react";

const Dashboard = React.lazy(() => import("../pages/dashboard/dashboard"));
const Schools = React.lazy(() => import("../pages/schools/list"));
const CreateSchools = React.lazy(() => import("../pages/schools/create"));
const EditSchool = React.lazy(() => import("../pages/schools/edit"));

const Parents = React.lazy(() => import("../pages/parents/list"));
const CreateParents = React.lazy(() => import("../pages/parents/create"));
const EditParents = React.lazy(() => import("../pages/parents/edit"));


const Drivers = React.lazy(() => import("../pages/drivers/list"));
const CreateDrivers = React.lazy(() => import("../pages/drivers/create"));
const EditDrivers = React.lazy(() => import("../pages/drivers/edit"));


const Students = React.lazy(() => import("../pages/students/list"));
const CreateStudents = React.lazy(() => import("../pages/students/create"));
const EditStudents = React.lazy(() => import("../pages/students/edit"));



const SchoolBusMap = React.lazy(() => import("../pages/school-bus/map"));
const ChangePassword = React.lazy(() => import("../pages/user/changePassword"));
const Profile = React.lazy(() => import("../pages/user/userProfile"));


const SchoolBuses = React.lazy(() => import("../pages/school-bus/list"));
const CreateSchoolBus = React.lazy(() => import("../pages/school-bus/create"));
const EditSchoolBus = React.lazy(() => import("../pages/school-bus/edit"));
const LiveMap = React.lazy(() => import("../pages/map/live-map"));
const SchoolProfile = React.lazy(() => import("../pages/schools/profile"));


const StudentGroups = React.lazy(() => import("../pages/student-group/list"));
const CreateStudentGroup = React.lazy(() => import("../pages/student-group/create"));
const EditStudentGroup = React.lazy(() => import("../pages/student-group/edit"));


const SchoolTrips= React.lazy(() => import("../pages/school-trip/list"));
const CreateSchoolTrip = React.lazy(() => import("../pages/school-trip/create"));
const EditSchoolTrip = React.lazy(() => import("../pages/school-trip/edit"));
const CloneSchoolTrip = React.lazy(() => import("../pages/school-trip/clone"));
const SchoolTripDetails = React.lazy(() => import("../pages/school-trip/details"));


const Settings = React.lazy(() => import("../pages/settings/settings"));
const GeneralSettings = React.lazy(() => import("../pages/settings/general-settings"));
const AppearanceSettings = React.lazy(() => import("../pages/settings/appearance-settings"));
const AuditLogs = React.lazy(() => import("../pages/audit-logs/list"));

const BusDepots = React.lazy(() => import("../pages/bus-depot/list"));
const CreateBusDepot = React.lazy(() => import("../pages/bus-depot/create"));
const EditBusDepot = React.lazy(() => import("../pages/bus-depot/edit"));


const routes = [
  { path: "/", exact: true, name: "home" },
  { path: "/dashboard", name: "dashboard", element: Dashboard },
  { path: "/schools", name: "schools", element: Schools },
  { path: "/schools/create", name: "create", element: CreateSchools },
  { path: "/schools/edit/:id", name: "edit", element: EditSchool },
  { path: "/parents", name: "parents", element: Parents },
  { path: "/parents/create", name: "create", element: CreateParents },
  { path: "/parents/edit/:id", name: "edit", element: EditParents },
  { path: "/drivers", name: "drivers", element: Drivers },
  { path: "/drivers/create", name: "create", element: CreateDrivers },
  { path: "/drivers/edit/:id", name: "edit", element: EditDrivers },
  { path: "/change-password", name: "changePassword", element: ChangePassword },
  { path: "/profile", name: "profile", element: Profile },
  { path: "/school-buses", name: "schoolBuses", element: SchoolBuses },
  { path: "/school-buses/create", name: "create", element: CreateSchoolBus },
  { path: "/school-buses/edit/:id", name: "edit", element: EditSchoolBus },
  { path: "/school-buses/map/:id", name: "map", element: SchoolBusMap },
  { path: "/live-map", name: "liveMap", element: LiveMap },
  { path: "/school-profile", name: "schoolProfile", element: SchoolProfile },
  { path: "/rider-groups", name: "studentGroups", element: StudentGroups },
  { path: "/rider-groups/create", name: "create", element: CreateStudentGroup },
  { path: "/rider-groups/edit/:id", name: "edit", element: EditStudentGroup },
  { path: "/school-trips", name: "schoolTrips", element: SchoolTrips },
  { path: "/school-trips/create", name: "create", element: CreateSchoolTrip },
  { path: "/school-trips/edit/:id", name: "edit", element: EditSchoolTrip },
  { path: "/school-trips/clone/:id", name: "clone", element: CloneSchoolTrip },
  { path: "/school-trips/details/:id", name: "details", element: SchoolTripDetails },
  { path: "/school-trips/simulation/:id", name: "details", element: SchoolTripSimulation },

  { path: "/settings", name: "settings", element: Settings },
  { path: "/settings/general", name: "general", element: GeneralSettings },
  { path: "/settings/appearance", name: "appearance", element: AppearanceSettings },
  { path: "/audit-logs", name: "auditLogs", element: AuditLogs },

  { path: "/students", name: "students", element: Students },
  { path: "/students/create", name: "create", element: CreateStudents },
  { path: "/students/edit/:id", name: "edit", element: EditStudents },
  { path: "/audit-logs", name: "auditLogs", element: AuditLogs },

  { path: "/bus-depots", name: "busDepots", element: BusDepots },
  { path: "/bus-depots/create", name: "create", element: CreateBusDepot },
  { path: "/bus-depots/edit/:id", name: "edit", element: EditBusDepot },
];

export const findRoute = routeName => {
  return routes.find(d => d.name == routeName);
};
export default routes;
