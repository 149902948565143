import jwtDecode from "jwt-decode";

const decodeToken = <T>(token: string) => {
  try {
    return jwtDecode<T>(token);
  } catch (e) {
    return null;
  }
};

export { decodeToken };