import React, { Suspense, useEffect } from "react";
import { HashRouter } from "react-router-dom";
import "./scss/style.scss";
import "@fontsource/poppins";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { IntlProvider } from "react-intl";
import { localeConfig, LocaleFormatter } from "./locales";
import { useAppSelector } from "@hooks/useAppHooks";
import trMessages from "devextreme/localization/messages/tr.json";
import enMessages from "devextreme/localization/messages/en.json";
import { locale, loadMessages } from "devextreme/localization";
import "leaflet/dist/leaflet.css";
import "axios-progress-bar/dist/nprogress.css";
import NiceModal from "@ebay/nice-modal-react";
import "axios-progress-bar/dist/nprogress.css";
import AppRoutes from "@navigation/app-routes";
import { loadAppearenceSettings } from "@stores/app.slice";
import { AppDispatch } from "@stores/index";
import { useDispatch } from "react-redux";
import themes from "devextreme/ui/themes";
import { appThemes } from "constants/app-themes";
import useAuth from "@hooks/userAuth";
import { SchoolAdmin } from "constants/roles";

const loading = <LoadIndicator id="large-indicator" height={60} width={60} />;
// Pages

const App = () => {
  const { userLocale } = useAppSelector(x => x.user);
  const { isAuthenticated } = useAppSelector(x => x.auth);
  const user = useAuth();
  const {theme} = useAppSelector(x => x.app);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (isAuthenticated&&user?.roles?.includes(SchoolAdmin)) {
      dispatch(loadAppearenceSettings());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    var devextremeTheme=appThemes.filter(d=>d.key==theme)[0]?.devextremeTheme;
    document.body.setAttribute('data-theme', theme)
    themes.current(devextremeTheme);
  }, [theme]);

  useEffect(() => {
    if (userLocale === "en-US") {
      locale("en");
      loadMessages(enMessages);
    } else if (userLocale === "tr-TR") {
      locale("tr");
      loadMessages(trMessages);
    }
  }, [locale]);
  return (
    (
      <IntlProvider
        locale={userLocale.split("_")[0]}
        messages={localeConfig[userLocale.replace("-","_")]}
      >
        <NiceModal.Provider>
          <HashRouter>
            <Suspense fallback={loading}>
              <AppRoutes />
            </Suspense>
          </HashRouter>
        </NiceModal.Provider>
      </IntlProvider>
    )
  );
};

export default App;
