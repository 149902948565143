import React from "react";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler
} from "@coreui/react";
import { AppSidebarNav } from "./app-sidebar-nav";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import _nav from "@navigation/_nav";
import { useAppSelector } from "@hooks/useAppHooks";
import companyLogo from "@assets/images/company-logo.svg";
import { AppDispatch } from "@stores/index";
import { useDispatch } from "react-redux";
import { appActions } from "@stores/app.slice";
import useAuth from "@hooks/userAuth";
const AppSidebar = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { sidebarShow, unfoldable } = useAppSelector(x => x.app);
  const user = useAuth();
  const grantedNav = _nav.filter(c => c.roles.includes(user.roles));
  return (
    <CSidebar
      onVisibleChange={(e)=>{
        dispatch(appActions.toggleSideBar(e))
      }}
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      narrow={unfoldable}
    >
      <CSidebarBrand className="d-none d-md-flex">
        {!unfoldable && <img src={companyLogo} alt="Engineerica" />}
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={grantedNav} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => {
          dispatch(appActions.toggleUnfoldable(!unfoldable));
        }}
      />
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
