export const saveAccessToken = (token: string): void => {
    localStorage.setItem('access_token', token);
  };
  
  export const getAccessToken = (): string => {
    return localStorage.getItem('access_token');
  };
  
  
  export const getLocale = (): string => {
    return localStorage.getItem('locale') ||"en-US";
  };
  export const deleteAccessToken = (): void => localStorage.removeItem('access_token');


  export const saveRefreshToken = (token: string): void => {
    localStorage.setItem('refresh_token', token);
  };
  
  export const getRefreshToken = (): string => {
    return localStorage.getItem('refresh_token');
  };
  
  
  export const deleteRefreshToken = (): void => localStorage.removeItem('refresh_token');


  export const saveTheme = (theme: string): void => {
    localStorage.setItem('theme', theme);
  };

  export const getTheme=():string=>{
    return localStorage.getItem('theme');
  }