import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";

import auth from "./auth.slice";
import app from "./app.slice";
import user from "./user.slice";
import livemap from "./live-map.slice";

const logger = createLogger();
const store = configureStore({
  reducer: {
    auth: auth,
    app: app,
    user: user,
    liveMap:livemap
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger)
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
