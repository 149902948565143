import API_ENPOINTS from "@api/endpoints";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import serverApi from "@api/core/serverApi";

export const signalrService = {
  async startConnection(hubUrl) {
    await serverApi.post(API_ENPOINTS.auth.tokenLogin, {});
    var access_token = localStorage.getItem("access_token");
    const url = `${process.env.REACT_APP_APP_URL}/${hubUrl}?access_token=${access_token}`;
    var connection = new HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 60000])
      .configureLogging(LogLevel.Information) // Adjust log level as needed
      .build();

    start();
    async function start() {
      try {
        await connection.start();
        connection.invoke("JoinAll");
        connection.onreconnected(() => {
          connection.invoke("JoinAll");
        });
      } catch (error) {
        console.error("Error while establishing SignalR connection:", error);
        setTimeout(() => start(), 5000); // Retry after 5 seconds
      }
    }

    return connection;
  },
};
