import React from "react";
import { useDispatch } from "react-redux";
import "./app-header.scss";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler
} from "@coreui/react";
import AppBreadcrumb from "@components/core/app-breadcrumb";
import AppHeaderDropdown from "./app-header-dropdown";
import AppHeaderLanguageSelectorDropdown from "./app-header-language-selector-dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { useAppSelector } from "@hooks/useAppHooks";
import { AppDispatch } from "@stores/index";
import { appActions } from "@stores/app.slice";
const AppHeader = () => {
  const { sidebarShow } = useAppSelector(x => x.app);
  const dispatch = useDispatch<AppDispatch>();
  return (
    <>
      <CHeader position="sticky" className="mb-3 app-header">
        <CContainer fluid>
          <CHeaderToggler
            className="ps-1"
            onClick={() => {
              dispatch(appActions.toggleSideBar(!sidebarShow))
            }}
          >
            {!sidebarShow &&<button className="btn btn-default"><i className="fa-solid fa-bars"></i></button> }
            {sidebarShow && <button className="btn btn-default"><i className="fa-solid fa-bars"></i></button>}
          </CHeaderToggler>
          <CHeaderBrand className="mx-auto d-md-none">
            <span>AccuBus</span>
          </CHeaderBrand>
          <CHeaderNav className="ms-3 bg-none">
            <AppHeaderLanguageSelectorDropdown />
            <AppHeaderDropdown />
          </CHeaderNav>
        </CContainer>
      </CHeader>
      <CContainer fluid className="mb-3 bread-crumb-container">
        <AppBreadcrumb />
        <CHeaderDivider />
      </CContainer>
    </>
  );
};

export default AppHeader;
