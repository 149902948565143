import React, { useContext } from "react";
import { Admin, SchoolAdmin } from "constants/roles";
import useAuth from "@hooks/userAuth";

type Props = {
  to: string;
  children: JSX.Element;
};

// This component is meant to be used everywhere a restriction based on user permission is needed
const Restricted: React.FunctionComponent<Props> = ({ to, children }) => {
  const user = useAuth();

  // If the user has that permission, render the children
  if (user?.roles?.includes(to)) {
    return <>{children}</>;
  }
  // Otherwise, do not render anything
  return null;
};

export default Restricted;
